import React from "react"
import UIKITheader from "../../components/ui-kit-header"
import Title from "../../components/title"
import Black from "../../components/black"
import *as Styles from "../../assets/css/ui-kit.css"

const Typography = (props) => {
    return (
        <div>
            <UIKITheader title="UNIDA Components Overview & Ui Kit"
                subtitle="Visual overview of the Unida Design System styles, components, their states, statuses & sizes."
            />
            <div className="Coloursimages">
                <div className="bg-white Rectangle p-5">
                    <Title title="Font family: Inter (Google Font : https://fonts.google.com/specimen/Inter?query=inter)" type={5} color="bordeaux" weight={300} />
                    <Black title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis ligula finibus, hendrerit lectus sit amet, consequat libero. Duis vehicula dignissim neque vitae ornare. Etiam augue felis, gravida rhoncus cursus et, cursus nec massa. Suspendisse fermentum erat ac vehicula accumsan. Donec eu congue urna. Aliquam enim mi, consectetur nec dignissim tincidunt, ornare quis purus. Morbi erat purus, sagittis scelerisque lorem ac, faucibus posuere lorem. Etiam finibus sed neque eu convallis. Etiam sollicitudin imperdiet augue, id feugiat mi semper sed. In tristique, sapien ac imperdiet ullamcorper, mauris tellus tincidunt tortor, sit amet ultricies justo diam eget ligula. Nam at euismod quam, non vulputate felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. " />
                </div>
                <div className="space2">
                </div>
                <div className="bg-white Rectangle p-5">
                    <Title title="Type Scale" type={6} color="bordeaux" weight={300} />
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-1 aligncenter-1">
                                <Title title="H1" color="grey-dark" type={6} className="size-14-line-17 d-flex align-items-center" />
                            </div>
                            <div className="col">
                                <Title title="Size 56px / Line 64px" color="bordeaux" type={1} className="size-56-line-64" />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-1 aligncenter-2">
                                <Title title="H2" color="grey-dark" type={6} className="size-14-line-17 d-flex align-items-center" />
                            </div>
                            <div className="col">
                                <Title title="Size 40px / Line 48px" color="green-dark" type={2} className="size-40-line-48" />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-1 aligncenter-3">
                                <Title title="H3" color="grey-dark" type={6} className="size-14-line-17 d-flex align-items-center" />
                            </div>
                            <div className="col">
                                <Title title="Size 24px / Line 29px" color="bordeaux" type={3} className="size-24-line-29" />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-1 aligncenter-4">
                                <Title title="H4" color="grey-dark" type={6} className="size-14-line-17 d-flex align-items-center" />
                            </div>
                            <div className="col">
                                <Title title="Size 22px / Line 26px" color="black" type={4} className="size-24-line-29" />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-1 aligncenter-3">
                                <Title title="H5" color="grey-dark" type={6} className="size-14-line-17 d-flex align-items-center" />
                            </div>
                            <div className="col">
                                <Title title="Size 16px / Line 20px" color="black" type={5} className="size-16-line-20" />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-1 aligncenter-5">
                                <Title title="Normal" color="grey-dark" type={6} className="size-14-line-17 d-flex align-items-center" />
                            </div>
                            <div className="col">
                                <Title title="Size-14-Line-17" color="black" type={6} className="size-14-line-17" />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col-1 aligncenter-7">
                                <Title title="Small" color="grey-dark" type={6} className="size-14-line-17 d-flex align-items-center" />
                            </div>
                            <div className="col">
                                <Title title="Size 12px / Line 16px" color="black" type={7} className="size-12-line-16" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space2">
                </div>
                <div className="bg-white Rectangle p-5">
                    <Title title="Font Styles" type={6} color="bordeaux" weight={300} />
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Title title="H1" type={6} color="black" weight={300} />
                            </div>
                            <div className="col-12 align-center">
                                <Title title="H1 Bold C - bordeaux" weight={900} type={1} color="bordeaux" className="size-56-line-64 bold" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col-4">
                                <Title title="H2" type={6} color="black" weight={300} />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col align-left">
                                <Title title="  H2 Medium L - bordeaux" weight={500} color="bordeaux" type={2} className="size-40-line-48 medium" />
                            </div>
                            <div className="col align-center">
                                <Title title="H2 Medium C - bordeaux" weight={500} color="bordeaux" type={2} className="size-40-line-48 medium" />
                            </div>
                            <div className="w-100"></div>
                            <div className="col align-left">
                                <Title title="H2 Medium L - green dark" weight={500} color="green-dark" type={2} className="size-40-line-48 medium" />
                            </div>
                            <div className="col align-center">
                                <Title title="H2 Medium C - green dark" weight={500} color="green-dark" type={2} className="size-40-line-48 medium" />
                            </div>
                            <div className="col-12 align-center">
                                <Title title="H2 Tablet Medium C - bordeaux" color="bordeaux" type={2} weight={500} className="tablet-size tablet-weight medium" />
                            </div>
                            <div className="col-12 align-center">
                                <Title title="H2 Tablet Medium C - bordeaux" color="green-dark" weight={500} type={2} className="tablet-size tablet-weight medium" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col">
                                <Title title="H3" type={6} color="black" weight={300} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 align-left">
                                <Title title="H3 Bold L - bordeaux" weight={900} color="bordeaux" type={3} className="size-24-line-29 bold" />
                            </div>
                            <div className="col-4 align-center">
                                <Title title="  H3 Bold C - bordeaux" weight={900} color="bordeaux" type={3} className="size-24-line-29 bold" />
                            </div>
                            <div className="col-12">
                                <Title title="  H3 Bold L - green dark" weight={900} color="green-dark" type={3} className="size-24-line-29 bold" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col">
                                <Title title="H4" type={6} color="black" weight={300} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 align-left">
                                <Title title="H4 Bold L - bordeaux" weight={900} color="bordeaux" type={4} className="size-22-line-26 bold" />
                            </div>
                            <div className="col-4 align-center">
                                <Title title="H4 Bold C - bordeaux" weight={900} color="bordeaux" type={4} className="size-22-line-26 bold" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="H4 Bold L - green dark" weight={900} color="green-dark" type={4} className="size-22-line-26 bold" />
                            </div>
                            <div className="col-12 align-center">
                                <Title title="H4 Medium C - bordeaux" weight={500} color="bordeaux" type={4} className="size-22-line-26 medium" />
                            </div>
                            <div className="col-12 align-center">
                                <Title title="  H4 Medium C - green dark" weight={500} color="green-dark" type={4} className="size-22-line-26 medium" />
                            </div>
                            <div className="col-12 align-left ">
                                <Title title="H4 Medium L - black" weight={500} color="black" type={4} className="size-22-line-26 medium" />
                            </div>
                            <div className="col-12 align-left light">
                                <Title title="H4 Light L - black" weight={300} color="black" type={4} className="size-22-line-26 light" />
                            </div>
                            <div className="col-12 align-left italic light">
                                <Title title="H4 Light ITALIC L - black" weight={300} color="black" type={4} className="size-22-line-26 ligth italic" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col">
                                <Title title="H5" type={6} color="black" weight={300} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-left ">
                                <Title title="H5 Bold L - black " weight={900} color="black" type={5} className="size-16-line-20 bold" />
                            </div>
                            <div className="col-4 align-left ">
                                <Title title="  H5 Medium L - black" weight={500} color="black" type={5} className="size-16-line-20 medium" />
                            </div>
                            <div className="col-4 align-center ">
                                <Title title="  H5 Medium L - black" weight={500} color="black" type={5} className="size-16-line-20 medium" />
                            </div>
                            <div className="col-4 align-right ">
                                <Title title="  H5 Medium R - black" weight={500} color="black" type={5} className="size-16-line-20 medium" />
                            </div>
                            <div className="col-4 align-left light">
                                <Title title="H5 Light L - black " weight={300} color="black" type={5} className="size-16-line-20 medium" />
                            </div>
                            <div className="col-4 align-center ">
                                <Title title="  H5 Light C - black" weight={300} color="black" type={5} className="size-16-line-20 medium" />
                            </div>
                            <div className="col-12 align-left light italic">
                                <Title title="H5 Light ITALIC L - black" weight={300} color="black" type={5} className="size-16-line-20 medium" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col">
                                <Title title="Normal" type={6} color="black" weight={300} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 align-left">
                                <Title title="NORMAL BOLD -L BORDEAUX" weight={900} color="bordeaux" type={6} className="size-14-line-17 underline bold" />
                            </div>
                            <div className="col-5 align-cemter">
                                <Title title="NORMAL BOLD -C BORDEAUX" weight={900} color="bordeaux" type={6} className="size-14-line-17 bold" />
                            </div>
                            <div className="col-12 align-center" >
                                <Title title="NORMAL BOLD -C GREEN DARK" weight={900} color="green-dark" type={6} className="size-14-line-17 bold" />
                            </div>
                            <div className="col-12 align-center">
                                <Title title="NORMAL BOLD -L GREEN DARK" weight={900} color="green-dark" type={6} className="size-14-line-17 bold underline" />
                            </div>
                            <div className="col-4 align-left">
                                <Title title="NORMAL BOLD -L GREY DARK" weight={900} color="grey-dark" type={6} className="size-14-line-17 bold underline" />
                            </div>
                            <div className="col-4 align-center">
                                <Title title="NORMAL BOLD -C GREY DARK" weight={900} color="grey-dark" type={6} className="size-14-line-17 bold underline" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Medium -L bordeaux" weight={500} color="bordeaux" type={6} className="size-14-line-17 medium" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Medium -L bordeaux" weight={500} color="bordeaux" type={6} className="size-14-line-17 medium underline" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Medium -L green dark" weight={500} color="green-dark" type={6} className="size-14-line-17 underline medium" />
                            </div>
                            <div className="col-4 align-left">
                                <Title title="Normal Medium L - black" weight={500} color="black" type={6} className="size-14-line-17 medium" />
                            </div>
                            <div className="col-4 align-center">
                                <Title title="Normal Medium L - black" weight={500} color="black" type={6} className="size-14-line-17 medium" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Medium L - black" weight={500} color="black" type={6} className="size-14-line-17 medium underline" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Medium L - grey dark" weight={500} color="grey-dark" type={6} className="size-14-line-17 medium" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Light L - bordeaux" weight={300} color="bordeaux" type={6} className="size-14-line-17 light" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Light L - black" weight={300} color="black" type={6} className="size-14-line-17 light" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Normal Light L - grey dark" weight={300} color="grey-dark" type={6} className="size-14-line-17 light" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col">
                                <Title title="Small" type={6} color="black" weight={300} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 align-left">
                                <Title title="SMALL BOLD L - BORDEAUX " weight={900} color="bordeaux" type={7} className="size-12-line-16 bold" />
                            </div>
                            <div className="col-4 align-center">
                                <Title title="SMALL BOLD C - BORDEAUX" weight={900} color="bordeaux" type={7} className="size-12-line-16 bold<" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="SMALL BOLD L - GREEN DARK " weight={900} color="green-dark" type={7} className="size-12-line-16 bold" />
                            </div>
                            <div className="col-6 align-left">
                                <Title title="Small Medium L - bordeaux " weight={500} color="bordeaux" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-6 align-right">
                                <Title title="Small Medium R - bordeaux" weight={500} color="bordeaux" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-12 align-left">
                                <Title title="Small Medium L - black " weight={500} color="black" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-4 align-left">
                                <Title title="Small Medium L - grey dark " weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-4 align-center">
                                <Title title="Small Medium R - grey dark" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-4 align-right">
                                <Title title="Small Medium R - grey dark" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Typography