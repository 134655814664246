import React from 'react'
import *as Styles from "../assets/css/ui-kit.css"
import Title from "./title"

const UIKITheader = (props) => {
    return(
        <div className="bg-white background-header">
            <Title title={ props.title } type={1} color="black" weight={500} />
            <Title title={ props.subtitle } type={4} color="bordeaux" weight={300} />
        </div>
    )
}


export default UIKITheader

